import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  getLocalStorage<T>(key: string): T {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  }

  setLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeLocalStrorage(...keys: string[]): void {
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  clearLocaStorage(): void {
    localStorage.clear();
  }
}
