import { AdminGuard } from './@guards/admin.guard';
import { ExaminerGuard } from './@guards/examiner.guard';
import { CandidateGuard } from './@guards/candidate.guard';
import { AuthGuard } from './@guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./@morooq/morooq.module').then((m) => m.MorooqModule),
  },
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'candidate',
        loadChildren: () =>
          import('./@candidate/candidate.module').then(
            (m) => m.CandidateModule
          ),
        canActivate: [CandidateGuard],
      },
      {
        path: 'examiner',
        loadChildren: () =>
          import('./@examiner/examiner.module').then((m) => m.ExaminerModule),
        canActivate: [ExaminerGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./@admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./@pages/payment/payment.module').then(
            (m) => m.PaymentModule
          ),
      },
      {
        path: 'take-exam',
        loadChildren: () =>
          import('./@candidate/TakeExam/take-exam/take-exam.module').then(
            (m) => m.TakeExamModule
          ),
      },
      {
        path: 'ielts-listening',
        loadChildren: () =>
          import('./@candidate/ielts-listening/ielts-listening.module').then(
            (m) => m.IeltsListeningModule
          ),
      },
      {
        path: 'ielts-reading',
        loadChildren: () =>
          import('./@candidate/ielts-reading/ielts-reading.module').then(
            (m) => m.IeltsReadingModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./_otherApps/other-apps.module').then(
            (m) => m.OtherAppsModule
          ),
      },
      {
        path: 'typing',
        redirectTo: '/assets/public/typing.html',
      },
    ],
  },
  {
    path: 'candidate',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'examiner',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
