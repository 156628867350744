// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/**
 * @ignore
 */
export const environment = {
  production: false,
  webApi: 'http://morooq.az/webservice/',
  stripeKey: 'pk_test_mAu0YX27q4uYAhqiP6LXOFhj',
  firebase: {
    apiKey: 'AIzaSyDfH9tmctXxMfi7-KwdpGOuBfmEsZ1m6a0',
    authDomain: 'barattson-exam.firebaseapp.com',
    databaseURL: 'https://barattson-exam.firebaseio.com',
    projectId: 'barattson-exam',
    storageBucket: 'barattson-exam.appspot.com',
    messagingSenderId: '426314178344',
    appId: '1:426314178344:web:5eb8f4f6b0f8419e93ba1d',
  },
  route: {
    learnMoreSpeaking:
      'http://app.morooq.az/ielts-trial/ielts-speaking-trial-exam',
    learnMoreWriting:
      'http://app.morooq.az/ielts-trial/ielts-writing-trial-exam',
    agentSignIn: 'http://app.morooq.az/agent/sign-in',
    faq: 'http://app.morooq.az/Home/faq',
    terms: 'http://app.morooq.az/Home/terms',
    privacy: 'http://app.morooq.az/Home/privacy',
    contactUs: 'http://app.morooq.az/Home/contactUs',
    examinerSignUp: 'http://app.morooq.az/Examiner/signin'
  },
  google: {
    reCaptchaSiteKey: '6LeMqqApAAAAAC_1TdWyRMvid3A3KvNwNT0gpVp0',
    reCaptchaSecretKey: '6LeMqqApAAAAACc-pMGbXw-f7FAtiHYcHfmdD9C1'
  }
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
