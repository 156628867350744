import { RouterService } from './router.service';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/helper/local-storage.service';
import {
  ISignIn,
  ISignWithSosial,
  ISignUp,
} from '../@core/models/auth.interface';
import { User } from '../@core/models/user';
import { ChangeInfo, ChangePassword } from '../@core/models/user/changeInfo';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.webApi;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser$: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private lsService: LocalStorageService,
    private routerService: RouterService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      this.lsService.getLocalStorage('currentUser')
    );
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value
      ? new User(this.currentUserSubject.value)
      : null;
  }

  public get isLogged(): boolean {
    return this.currentUserValue !== null;
  }

  signInCandidate(model: ISignIn) {
    return this.http
      .post<User>(`${environment.webApi}api/auth/candidate/sign-in`, model)
      .pipe(map((user: User) => this.writeUserLocalStorage(user)));
  }

  signInExaminer(model: ISignIn) {
    return this.http
      .post<User>(`${environment.webApi}api/auth/examiner/sign-in`, model)
      .pipe(map((user: User) => this.writeUserLocalStorage(user)));
  }

  signInAdmin(model: ISignIn) {
    return this.http
      .post<User>(`${environment.webApi}api/auth/employee-admin/sign-in`, model)
      .pipe(map((user: User) => this.writeUserLocalStorage(user)));
  }

  signInWithSosial(model: ISignWithSosial) {
    return this.http
      .post(`${environment.webApi}api/auth/candidate/social`, model)
      .pipe(map((user: User) => this.writeUserLocalStorage(user)));
  }

  signUpCandidate(model: ISignUp) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/sign-up`,
      model
    );
  }

  signOut() {
    this.lsService.clearLocaStorage();
    this.currentUserSubject.next(null);
    if (this.routerService.getRoute() === 'candidate') {
      this.router.navigate(['/candidate/sign-in']);
    } else if (this.routerService.getRoute() === 'examiner') {
      this.router.navigate(['/examiner/sign-in']);
    } else {
      this.router.navigate(['/admin/sign-in']);
    }
  }

  // email confirm code
  emailConfirmCode(code: string) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/confirm-email?confirmCode=${code}`,
      null
    );
  }

  // confirm email
  resendConfirmEmail(email: string) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/resend-emailconfirmation-link?email=${email}`,
      null
    );
  }

  // send email for forget password
  sendEmailforCandidate(email: string) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/send-email-forgot-password?email=${email}`,
      null
    );
  }

  sendEmailforExaminer(email: string) {
    return this.http.post(
      `${environment.webApi}api/auth/examiner/send-email-forgot-password?email=${email}`,
      null
    );
  }
  // send code for forget password
  sendCodeCandidate(email: string, code: string) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/validate-reset-password?email=${email}&code=${code}`,
      null
    );
  }

  sendCodeExaminer(email: string, code: string) {
    return this.http.post(
      `${environment.webApi}api/auth/examiner/validate-reset-password?email=${email}&code=${code}`,
      null
    );
  }

  // reset password
  resetPasswordCandidate(
    email: string,
    code: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this.http.post(
      `${environment.webApi}api/auth/candidate/change-forgot-password`,
      {
        email,
        code,
        newPassword,
        confirmPassword,
      }
    );
  }

  resetPasswordExaminer(
    email: string,
    code: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this.http.post(
      `${environment.webApi}api/auth/examiner/change-forgot-password`,
      {
        email,
        code,
        newPassword,
        confirmPassword,
      }
    );
  }

  writeUserLocalStorage(user: User) {
    const newUser = new User(user);
    this.lsService.setLocalStorage('currentUser', newUser);
    this.currentUserSubject.next(newUser);
  }

  updateUserinfo(userInfo: ChangeInfo) {
    if (this.routerService.getRoute() === 'candidate')
      return this.http
        .post(
          this.baseUrl +
            'api/candidates/change-name?' +
            'firstName=' +
            userInfo.firstName +
            '&lastName=' +
            userInfo.lastName +
            '&personId=' +
            userInfo.personId,
          null
        )
        .pipe(
          map((message) => {
            const user: User = this.lsService.getLocalStorage('currentUser');
            user.Name = userInfo.firstName;
            user.Surname = userInfo.lastName;
            this.writeUserLocalStorage(user);
            window.location.reload();
            return message;
          })
        );
    return this.http
      .post(
        this.baseUrl +
          'api/examiners/change-name?' +
          'firstName=' +
          userInfo.firstName +
          '&lastName=' +
          userInfo.lastName +
          '&personId=' +
          userInfo.personId,
        null
      )
      .pipe(
        map((message) => {
          const user: User = this.lsService.getLocalStorage('currentUser');
          user.Name = userInfo.firstName;
          user.Surname = userInfo.lastName;
          this.writeUserLocalStorage(user);
          window.location.reload();
          return message;
        })
      );
  }

  updatePassword(userPasswords: ChangePassword) {
    return this.http.post(
      this.baseUrl + 'api/users/change-password',
      userPasswords
    );
  }
}
