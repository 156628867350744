import { httpInterceptorProviders } from './@core/interceptors/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { PostAttendingComponent } from './@candidate/TakeExam/post-attending/post-attending.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EvaluateComponent } from './@examiner/evaluate/evaluate.component';
import { IntercomModule } from 'ng-intercom';
import { QuillModule } from 'ngx-quill';

import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

@NgModule({
  declarations: [AppComponent, PostAttendingComponent, EvaluateComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
    AngularFireModule.initializeApp(
      environment.firebase,
      'angular-auth-firebase'
    ),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    IntercomModule.forRoot({
      appId: 'yilpmxjy', // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [['bold', 'italic', 'underline']],
      },
    }),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({key: 'AddReadingState'}),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [httpInterceptorProviders, {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.google.reCaptchaSiteKey,
  }],
})
export class AppModule {}
