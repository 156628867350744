import { EPersonType } from './../@core/enums/person_type';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../@sevices/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ExaminerGuard implements CanActivate {
  constructor(private authService: AuthService, private location: Location) {}
  canActivate(): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      map((user) => {
        if (user.PersonType === EPersonType.Examiner) return true;
        this.location.back();
      })
    );
  }
}
