export class User {
  AccessToken?: string;
  RefreshToken?: string;
  Expiration?: string;
  Name?: string;
  Surname?: string;
  Email: string;
  UserID: number;
  PersonID: number;
  PersonType: number;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  public get fullName(): string {
    if (this.Name !== this.Surname) return `${this.Name} ${this.Surname}`;
    else return this.Name;
  }

  public set fullName(name: string) {
    const parts = name.split(' ');
    if (parts.length != 2) {
      throw new Error('Invalid name format: first last');
    }
    this.Name = parts[0];
    this.Surname = parts[1];
  }
}
