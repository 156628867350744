import { RouterService } from './../@sevices/router.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../@sevices/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private routerService: RouterService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const stateUrl: string = state.url;
    this.routerService.sendRoute(stateUrl);
    const url = this.routerService.getRoute();
    return this.authService.currentUser$.pipe(
      map((user) => {
        if (user) return true;
        this.router.navigateByUrl(`${url}/sign-in`);
      })
    );
  }
}
