import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthModule } from '../auth/auth.module';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  public route = new BehaviorSubject<any>('');

  constructor() {}

  sendRoute(value: any) {
    this.route.next({ text: value });
  }

  getRoute(): string {
    return this.route.value.text.match(/([^/]+)/)[0];
  }
}
